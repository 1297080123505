import React from 'react'
import { Link } from 'gatsby'

import Logo from 'images/logo.inline.svg'

const Component = () => {
  return (
    <Link to="/" className="block" aria-label="Zur Startseite">
      <Logo className="max-w-full" />
    </Link>
  )
}

export default Component
