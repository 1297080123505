/* eslint-disable no-restricted-globals */
import React from 'react'

const scrollTo = (id) => () => {
  document.querySelector(id).scrollIntoView({ behavior: 'smooth' })

  setTimeout(() => {
    if (history.pushState) {
      history.pushState(null, null, id)
    } else {
      location.hash = id
    }
  }, 200)
}

const Scroller = ({ component: Component = 'a', targetId, ...props }) => {
  return <Component onClick={scrollTo(targetId)} {...props} />
}

export default Scroller
