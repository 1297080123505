import React from 'react'
import CookieConsent from 'react-cookie-consent'

import { TRACKING_COOKIE } from 'utils'

const Consent = () => {
  return (
    <div className="fixed inset-0 top-auto z-50" style={{ bottom: '1rem' }}>
      <div className="container">
        <CookieConsent
          buttonText="Accept"
          declineButtonText="Deny"
          enableDeclineButton
          cookieName={TRACKING_COOKIE}
          cookieValue={true}
          declineCookieValue={false}
          containerClasses="max-w-screen-lg md:flex md:items-center p-4 mx-auto space-y-4 md:space-y-0 bg-indigo-50 rounded text-indigo-400 text-sm"
          contentClasses="flex-grow pr-8"
          buttonWrapperClasses="flex flex-shrink-0 items-center justify-end space-x-4"
          buttonClasses="px-3 py-2 rounded bg-indigo-400 font-medium leading-none text-white hover:bg-indigo-600"
          declineButtonClasses="px-3 py-2 rounded leading-none"
          disableStyles={true}
          expires={150}
          // eslint-disable-next-line no-restricted-globals
          onAccept={() => location.reload()}
          // eslint-disable-next-line no-restricted-globals
          onDecline={() => location.reload()}
        >
          This website uses cookies to ensure you get the best experience on our
          website.
        </CookieConsent>
      </div>
    </div>
  )
}

export default Consent
