import React, { useState } from 'react'
import { useKey } from 'react-use'
import clsx from 'clsx'

const cols = [...Array(12).keys()]

// TODO: Add ability to change col count live

const GridDebug = () => {
  const [show, setShow] = useState(false)
  const handleKeyDown = ({ ctrlKey }) => ctrlKey && setShow((show) => !show)

  useKey('g', handleKeyDown)

  if (!show) {
    return null
  }

  return (
    <section
      className="fixed inset-0 pointer-events-none"
      style={{ zIndex: 999999 }}
    >
      <div className="fixed inset-x-0 top-0 flex justify-center z-50">
        <div className="flex bg-red-500 text-white text-xs font-medium px-4 py-2">
          <div>Breakpoint:&nbsp;</div>
          <div className="hidden xs:block sm:hidden">xs</div>
          <div className="hidden sm:block md:hidden">sm</div>
          <div className="hidden md:block lg:hidden">md</div>
          <div className="hidden lg:block xl:hidden">lg</div>
          <div className="hidden xl:block">xl</div>
        </div>
      </div>
      <div className="container">
        <div className="grid grid-cols-4 md:grid-cols-12 gap-8">
          {cols.map((col, index) => (
            <div
              key={`col-${index}`}
              className={clsx('col-span-1', index > 3 && 'hidden md:block')}
            >
              <div className="bg-red-300 bg-opacity-25 h-screen flex items-center justify-center text-red-900 font-medium">
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default GridDebug
