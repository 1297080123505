import React from 'react'

import { SIGN_UP_URL } from 'utils'

import Logo from 'components/Logo'
import Scroller from 'components/Scroller'
import Globe from 'images/icons/globe.inline.svg'
import Palette from 'images/icons/palette.inline.svg'

const Component = () => (
  <div className="w-full py-4 sm:py-8 flex-shrink-0 bg-white">
    <div className="container">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        <Logo />

        <div className="col-span-2 hidden md:flex justify-center items-center space-x-6 text-xs uppercase tracking-wider">
          <div className="opacity-40">Solutions:</div>
          <Scroller
            component="button"
            targetId="#websites"
            className="flex items-center font-bold uppercase tracking-wider"
          >
            <Globe className="mr-1.5" />
            <div className="leading-0">Client Websites</div>
          </Scroller>
          <Scroller
            component="button"
            targetId="#portfolio"
            className="flex items-center font-bold uppercase tracking-wider"
          >
            <Palette className="mr-1.5" />
            Porftolio
          </Scroller>
        </div>

        <div className="text-right">
          <a href={SIGN_UP_URL} className="font-bold">
            Apply
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Component
