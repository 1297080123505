import React from 'react'
import { Link } from 'gatsby'

import Logo from 'components/Logo'

const legal = [
  { label: 'Contact', route: 'mailto:hvoostik@gmail.com' },
  { label: 'Imprint', route: '/imprint' },
]

const Component = () => {
  return (
    <div className="py-8 md:py-16 lg:py-24 flex-shrink-0">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="text-center md:text-right">
            <ul className="inline-flex -m-4 items-center">
              {legal.map(({ label, route }, index) => (
                <React.Fragment key={index}>
                  <li className="px-4 py-2">
                    {route.startsWith('/') ? (
                      <Link to={route} className="block py-2 hover:underline">
                        {label}
                      </Link>
                    ) : (
                      <a href={route} className="block py-2 hover:underline">
                        {label}
                      </a>
                    )}
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>

          <div className="flex flex-col md:flex-row items-center md:max-w-xs space-y-4 md:space-y-0 md:space-x-4 md:order-first">
            <div className="flex-shrink-0">
              <Logo />
            </div>
            <p className="text-sm -mt-1.5 text-center md:text-left">
              cmd-v helps designers generate copy for their designs.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
