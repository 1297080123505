import React from 'react'

import SEO from 'components/Seo'
import Header from 'components/Header'
import Footer from 'components/Footer'

import CookieConsent from 'components/CookieConsent'

import GridDebug from 'components/GridDebug'

const Main = (props) => <main className="flex-grow" {...props} />

const Layout = (props) => {
  const { seo = {}, children } = props

  return (
    <>
      <SEO {...seo} />
      <Header />
      <Main>{children}</Main>
      <Footer />
      <GridDebug />
      <CookieConsent />
    </>
  )
}

export default Layout
